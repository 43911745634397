import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Home from "./pages/Home/home";
import Login from "./pages/Login/login";
import Password from "./pages/Resetpassword/reset";
import Dashboard from "./pages/Dashboard/dashboard";
import Juntas from "./pages/Juntas/juntas";
import Mobile from "./pages/Mobile/mobile";
import "./App.css";

function App() {
  let body = document.querySelector("body");
  if (!isMobile) {
    body.style.overflow = "hidden";
  } else {
    body.style.overflow = "scroll";
  }

  if (!isMobile) {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route path="/" exact component={Home}></Route>
            <Route path="/login" exact component={Login}></Route>
            <Route path="/reset-password" exact component={Password}></Route>
            <Route path="/dashboard" exact component={Dashboard}></Route>
            <Route
              path="/sala-de-juntas/:user"
              exact
              component={Juntas}
            ></Route>
          </Switch>
        </Router>
      </div>
    );
  } else {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route path="/" exact component={Mobile} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;

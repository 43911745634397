import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./admin.css";

function AdminPanel() {
  const [srcForm, setSrcForm] = useState("");
  const [dropdownCreditos, setDropdownCreditos] = useState(false);
  const [dropdownRegistro, setDropdownRegistro] = useState(false);
  const [dropdownPrepararJunta, setDropdownPrepararJunta] = useState(false);
  const [dropdownJunta, setDropdownJunta] = useState(false);
  const [dropdownComunidades, setDropdownComunidades] = useState(false);
  const [dropdownSaldo, setDropdownSaldo] = useState(false);
  const [dropdownPrivadosVoto, setDropdownPrivadosVoto] = useState(false);
  const [mostrarPlantillas, setMostrarPlantillas] = useState(false);
  const history = useHistory();

  const handleClick = (action) => {
    clearDropdowns();
    setMostrarPlantillas(false);
    const urlBase = "https://portal.r2docuo.com/abogadosymas/forms/";
    const embed = "?embed=1";
    if (action === "subir") {
      setMostrarPlantillas(true);
    }
    setSrcForm(urlBase + action + embed);
  };

  function clearDropdowns() {
    setDropdownComunidades(false);
    setDropdownSaldo(false);
    setDropdownRegistro(false);
    setDropdownCreditos(false);
    setDropdownPrepararJunta(false);
    setDropdownJunta(false);
    setDropdownPrivadosVoto(false);
  }

  const onMouseClick = (id) => {
    clearDropdowns();
    if (id === "saldo") {
    } else if (id === "registro") {
      setDropdownRegistro(!dropdownRegistro);
    } else if (id === "comunidades") {
      setDropdownComunidades(!dropdownComunidades);
    } else if (id === "prepararjunta") {
      setDropdownPrepararJunta(!dropdownPrepararJunta);
    } else if (id === "junta") {
      setDropdownJunta(!dropdownJunta);
    } else {
    }
  };

  const onMouseEnter = (id) => {
    if (id === "revisarPrivadosVoto") {
      setDropdownPrivadosVoto(true);
    } else if (id === "consultarCredito") {
      setDropdownSaldo(true);
    } else if (id === "comprarCredito") {
      setDropdownCreditos(true);
    }
  };

  const onMouseLeave = (id) => {
    if (id === "revisarPrivadosVoto") {
      setDropdownPrivadosVoto(false);
    } else if (id === "consultarCredito") {
      setDropdownSaldo(false);
    } else if (id === "comprarCredito") {
      setDropdownCreditos(false);
    }
  };

  return (
    <>
      <div id="AdComunidades">
        <div id="botonesAdComunidad">
          <ul>
            <li onClick={() => onMouseClick("registro")}>
              <i className="fas fa-user-edit iconButton"></i>
              Registro de comunidades
              <i className="fas fa-caret-right dashboard-admin-alignRight"></i>
              {dropdownRegistro ? (
                <ul className="dashboard-admin-dropdown-menu">
                  <li
                    className="dashboard-admin-dropdown-menu-item"
                    onClick={() => handleClick("altacomunidadpropietarios")}
                  >
                    Registrar una comunidad
                  </li>
                  <li
                    className="dashboard-admin-dropdown-menu-item"
                    onClick={() => handleClick("altainmueble")}
                  >
                    Registrar propietarios
                  </li>
                  <li
                    className="dashboard-admin-dropdown-menu-item"
                    onClick={() => handleClick("subir")}
                  >
                    Registrar por excel
                  </li>
                </ul>
              ) : undefined}
            </li>
            <li onClick={() => onMouseClick("comunidades")}>
              <i className="fas fa-atlas iconButton"></i>
              Gestión de monedero
              <i className="fas fa-caret-right dashboard-admin-alignRight"></i>
              {dropdownComunidades ? (
                <ul className="dashboard-admin-dropdown-menu">
                  <li
                    className="dashboard-admin-dropdown-menu-item"
                    onClick={() => handleClick("reasignar")}
                  >
                    Asignar créditos a comunidad
                  </li>
                  <li
                    className="dashboard-admin-dropdown-menu-item"
                    onMouseEnter={() => onMouseEnter("comprarCredito")}
                    onMouseLeave={() => onMouseLeave("comprarCredito")}
                  >
                    Comprar CrediConvos
                    <i className="fas fa-caret-right dashboard-admin-alignRight"></i>
                    {dropdownCreditos && (
                      <ul className="dashboard-admin-dropdown-menu">
                        <li
                          className="dashboard-admin-dropdown-menu-item"
                          className="dashboard-admin-dropdown-menu-item"
                          onClick={() =>
                            handleClick("pedidofacturaadministracion")
                          }
                        >
                          Para la comunidad
                        </li>
                        <li
                          className="dashboard-admin-dropdown-menu-item"
                          className="dashboard-admin-dropdown-menu-item"
                          onClick={() => handleClick("pedidofacturacomunidad")}
                        >
                          Para el administrador
                        </li>
                      </ul>
                    )}
                  </li>
                  <li
                    className="dashboard-admin-dropdown-menu-item"
                    onMouseEnter={() => onMouseEnter("consultarCredito")}
                    onMouseLeave={() => onMouseLeave("consultarCredito")}
                  >
                    Consultar crédito
                    <i className="fas fa-caret-right dashboard-admin-alignRight"></i>
                    {dropdownSaldo && (
                      <ul className="dashboard-admin-dropdown-menu">
                        <li
                          className="dashboard-admin-dropdown-menu-item"
                          onClick={() =>
                            handleClick("consultacreditoadministrador")
                          }
                        >
                          Crédito administración
                        </li>
                        <li
                          className="dashboard-admin-dropdown-menu-item"
                          onClick={() =>
                            handleClick("consultacreditoscomunidad")
                          }
                        >
                          Crédito comunidad
                        </li>
                      </ul>
                    )}
                  </li>
                </ul>
              ) : undefined}
            </li>
            <li onClick={() => onMouseClick("prepararjunta")}>
              <i className="fas fa-chalkboard-teacher iconButton"></i>
              Prepara una junta
              <i className="fas fa-caret-right dashboard-admin-alignRight"></i>
              {dropdownPrepararJunta ? (
                <ul className="dashboard-admin-dropdown-menu">
                  <li
                    className="dashboard-admin-dropdown-menu-item"
                    onClick={() => handleClick("preparalajunta")}
                  >
                    Prepara la convocatoria
                  </li>
                  <li
                    className="dashboard-admin-dropdown-menu-item"
                    onClick={() => handleClick("revisadelegaciondevoto")}
                  >
                    Revisar delegaciones de voto
                  </li>

                  <li
                    onMouseEnter={() => onMouseEnter("revisarPrivadosVoto")}
                    onMouseLeave={() => onMouseLeave("revisarPrivadosVoto")}
                    className="dashboard-admin-dropdown-menu-item"
                  >
                    Privados de voto
                    <i className="fas fa-caret-right administrador-flecha-desplegable"></i>
                    {dropdownPrivadosVoto && (
                      <ul className="dashboard-admin-dropdown-menu">
                        <li
                          className="dashboard-admin-dropdown-menu-item"
                          onClick={() => handleClick("privadovoto")}
                        >
                          Registrar privados de voto
                        </li>
                        <li
                          className="dashboard-admin-dropdown-menu-item"
                          onClick={() => handleClick("pagomoroso")}
                        >
                          Comunicar pago de morosos
                        </li>
                      </ul>
                    )}
                  </li>
                </ul>
              ) : undefined}
            </li>
            <li onClick={() => onMouseClick("junta")}>
              <i className="fas fa-coins iconButton"></i>
              Junta
              <i className="fas fa-caret-right administrador-flecha-desplegable dashboard-admin-alignRight"></i>
              {dropdownJunta ? (
                <ul className="dashboard-admin-dropdown-menu">
                  <li
                    className="dashboard-admin-dropdown-menu-item"
                    onClick={() =>
                      history.push("/sala-de-juntas/administrador")
                    }
                  >
                    Iniciar la junta
                  </li>
                  <li
                    className="dashboard-admin-dropdown-menu-item"
                    onClick={() => handleClick("revisarjunta")}
                  >
                    Revisar junta
                  </li>
                </ul>
              ) : undefined}
            </li>
            <li
              onClick={() =>
                window.location.replace(
                  "https://convoco.online/recordar-codigo/"
                )
              }
            >
              <i className="fas fa-key iconButton"></i>
              Recuperar código administrador
            </li>
          </ul>
        </div>
        <div id="contFormAdComunidad">
          <iframe
            id="formAdComunidades"
            title="Vista de contenido del panel de control"
            src={srcForm}
          />
          {mostrarPlantillas && (
            <div className="dashboard-admin-document-download-container">
              <a
                href="https://portal.r2docuo.com/abogadosymas/document?LF24139CF6"
                target="_blank"
                className="dashboard-admin-document-example-download-button"
              >
                Plantilla importación datos comunidad
              </a>
              <a
                href="https://portal.r2docuo.com/abogadosymas/document?L397350485"
                target="_blank"
                className="dashboard-admin-document-example-download-button"
              >
                Plantilla importación datos propietarios
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default AdminPanel;

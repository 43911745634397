import React, { useState } from "react";
import { Session } from "bc-react-session";
import gPlay from "../../assets/images/gPlay.jpg";
import aStore from "../../assets/images/appstore.png";
import "./mobile.css";
import Navbar from "./components/navbar/navbar";

export default function Mobile() {
  const { payload } = Session.get();
  const [displayVotes, setDisplayVotes] = useState(false);
  const [showStores, setShowStores] = useState(false);
  const [vote, setVote] = useState(0);
  const [srcForm, setSrcForm] = useState("");
  let sesion = Object.entries(payload).length;

  const openPlayStore = () => {
    window.location.replace("market://details?id=org.jitsi.meet");
  };

  const openAppStore = () => {
    window.location.replace(
      "itms-apps:https://apps.apple.com/us/app/jitsi-meet/id1165103905"
    );
  };

  const handleUser = (user) => {
    console.log("patata");
    Session.setPayload({
      user: user,
    });
    window.location.reload();
  };

  const handleVotes = (action) => {
    const forms = [
      "https://portal.r2docuo.com/abogadosymas/forms/votorepresentante", //representante
      "https://portal.r2docuo.com/abogadosymas/forms/votopropietario", //propietario
    ];
    const embed = "?embed=1";

    if (payload.user === "representante") {
      setSrcForm(forms[0] + action + vote + embed);
    } else if (payload.user === "propietario") {
      setSrcForm(forms[1] + action + vote + embed);
    }
  };

  function clearDisplays() {
    setDisplayVotes(false);
    setShowStores(false);
    setSrcForm("");
  }

  const handleChange = (option) => {
    clearDisplays();
    const urlBase = "https://portal.r2docuo.com/abogadosymas/forms/";
    const embed = "?embed=1";
    if (option !== "asistenciapropietario" && option !== "eVoto") {
      setSrcForm(urlBase + option + embed);
    } else {
      if (option === "asistenciapropietario") {
        setShowStores(!showStores);
        let user = Session.get().payload.user;

        if (user === "representante") {
          setSrcForm(urlBase + "asistenciarepresentante" + embed);
        } else {
          setSrcForm(urlBase + "asistenciapropietarioonline" + embed);
        }
      } else {
        setDisplayVotes(true);
      }
    }
  };

  return (
    <>
      <Navbar />
      <div id="MobileViewGeneral">
        <div id="mobilePageView">
          <h2>¡Bienvenido!</h2>
          {sesion === 0 ? (
            <>
              <p id="mobile-user-text">¿Eres propietario?</p>
              <button
                className="mobile-button-user"
                onClick={() => handleUser("propietario")}
              >
                Si
              </button>
              <button
                className="mobile-button-user"
                onClick={() => handleUser("representante")}
              >
                No
              </button>
            </>
          ) : (
            <>
              {showStores && (
                <div id="contStore">
                  <p>
                    Te recordamos que necesitarás la aplicación de{" "}
                    <strong>Jitsi-Meet</strong> instalada en tu dispositivo para
                    asistir a la junta, para instalarla toca en una de estas dos
                    imágenes:
                  </p>
                  <div id="contAppStore" style={{ textAlign: "center" }}>
                    <img
                      src={gPlay}
                      onClick={openPlayStore}
                      className="bStoreMobile"
                      alt="Google Play Store Logo"
                    />
                    <img
                      src={aStore}
                      onClick={openAppStore}
                      className="bStoreMobile"
                      alt="Apple App Store Logo"
                    />
                  </div>
                </div>
              )}
              <p>¿Dónde quieres ir?</p>
              <select
                id="sMobileView"
                onChange={(event) => handleChange(event.target.value)}
              >
                <option value="0" style={{ display: "none" }}>
                  Quiero ir a...
                </option>
                <optgroup label="Antes de la convocatoria">
                  <option value="consultapropietarios">
                    Revisar y actualizar mis datos
                  </option>
                  <option value="solcitudpropietario">
                    Proponer un punto del día
                  </option>
                </optgroup>
                <optgroup label="¿Has recibido una convocatoria?">
                  <option value="proximaconvocatoria">
                    Consultar la convocatoria
                  </option>
                  <option value="solicituddelegacion">Delegar mi voto</option>
                  <option value="solicitudrevocacion">Revocar mi voto</option>
                </optgroup>
                <optgroup label="Junta de vecinos">
                  <option value="asistenciapropietario">
                    Registrar mi asistencia
                  </option>
                  <option value="eVoto">Votar punto de la junta</option>
                </optgroup>
              </select>
              {displayVotes && (
                <>
                  <select
                    className="sMobileView"
                    onChange={(e) => setVote(e.target.value)}
                  >
                    <option value="0" style={{ display: "none" }}>
                      Selecciona el punto a votar
                    </option>
                    <option value="1">Punto 1</option>
                    <option value="2">Punto 2</option>
                    <option value="3">Punto 3</option>
                    <option value="4">Punto 4</option>
                    <option value="5">Punto 5</option>
                    <option value="6">Punto 6</option>
                    <option value="7">Punto 7</option>
                    <option value="8">Punto 8</option>
                    <option value="9">Punto 9</option>
                    <option value="10">Punto 10</option>
                    <option value="11">Punto 11</option>
                    <option value="12">Punto 12</option>
                  </select>
                  <select
                    className="sMobileView"
                    onChange={(event) => handleVotes(event.target.value)}
                  >
                    <option value="0" style={{ display: "none" }}>
                      Selecciona el sentido del voto
                    </option>
                    <option value="favorpunto">A favor</option>
                    <option value="contrapunto">En contra</option>
                    <option value="abstencionpunto">Abstencion</option>
                  </select>
                </>
              )}
            </>
          )}
        </div>
        <iframe
          id="iframeMobileView"
          src={srcForm}
          title="Fourmulario de interacción"
        />
      </div>
    </>
  );
}

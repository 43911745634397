import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Session } from "bc-react-session";
import logo from "../../assets/images/conmiapp_logo.jpeg";
import axios from "axios";
import Input from "./components/input/input";
import Label from "./components/Label/label";
import Title from "./components/Title/title";
import "./login.css";

const Login = () => {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [hasError, setHasError] = useState(false);
  const history = useHistory();
  const { payload } = Session.get();

  if (Object.entries(payload).length !== 0) history.push("/dashboard");

  function handleChange(name, value) {
    if (name === "usuario") {
      setUser(value);
    } else {
      if (value.length < 8) {
        setPasswordError(true);
      } else {
        setPasswordError(false);
        setPassword(value);
      }
    }
  }

  async function ifMatch(param) {
    if (param.user.length > 0 && param.password.length > 0) {
      await axios
        .post("https://backend.convoco.online/auth/local", {
          identifier: param.user,
          password: param.password,
        })
        .then((response) => {
          Session.setPayload({
            id: response.data.user.internal_id,
            user: response.data.user.email,
            name: response.data.user.username,
            type: response.data.user.type,
            jwt: response.data.jwt,
          });
          history.push("/dashboard");
        })
        .catch((err) => {
          console.log(err);
          setHasError(true);
        });
    } else {
      setHasError(true);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    let account = { user, password };
    if (account) {
      ifMatch(account);
    }
  }

  return (
    <div>
      <div className="login-navbar-container">
        <Link to="/">
          <img src={logo} className="login-navbar-logo" alt="Conmiapp Logo" />{" "}
        </Link>
      </div>
      <div className="login-container">
        <Title text="¡Bienvenido!" />
        {hasError && (
          <label className="label-alert-error">
            Su usuario o contraseña son incorectos o no existen en nuestra
            plataforma
          </label>
        )}
        <Label text="Usuario" />
        <Input
          attribute={{
            id: "usuario",
            name: "usuario",
            type: "text",
            placeholder: "Email",
          }}
          handleChange={handleChange}
        />
        <Label text="Contraseña" />
        <Input
          attribute={{
            id: "contraseña",
            name: "contraseña",
            type: "password",
            placeholder: "Ingresa tu contraseña",
          }}
          handleChange={handleChange}
          param={passwordError}
        />
        <Link to="reset-password" className="login-forgot-password">
          ¿Has olvidado tu contraseña?
        </Link>
        {passwordError && (
          <label className="label-error">
            La contraseña debe tener un minimo de 6 carácteres
          </label>
        )}
        <button className="login-button" onClick={(e) => handleSubmit(e)}>
          Ingresar
        </button>
      </div>
    </div>
  );
};

export default Login;

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "./components/navbar/navbar";
import ControlPanelPropietario from "./components/controlPanel/propietario/cProp";
import ControlPanelRepresentante from "./components/controlPanel/representante/cRepr";
import ControlPanelAdministrador from "./components/controlPanel/administrador/cAdmin";
import Videoconference from "./components/videoconferencia/videoconference";
import "./juntas.css";

export default function Juntas() {
  const { user } = useParams();
  const [srcForm, setSrcForm] = useState("");
  const forms = [
    "https://portal.r2docuo.com/abogadosymas/forms/votopresencial", //administrador
    "https://portal.r2docuo.com/abogadosymas/forms/votorepresentante", //representante
    "https://portal.r2docuo.com/abogadosymas/forms/votopropietario", //propietario
  ];
  const embed = "?embed=1";

  function handleClick(action, point) {
    if (action === "registro") {
      if (point === 1) {
        //ADMINISTRADOR
        setSrcForm(
          "https://portal.r2docuo.com/abogadosymas/forms/asistentepresencial"
        );
      } else if (point === 2) {
        //REPRESENTANTE
        setSrcForm(
          "https://portal.r2docuo.com/abogadosymas/forms/asistenciarepresentante"
        );
      } else {
        //PROPIETARIO
        setSrcForm(
          "https://portal.r2docuo.com/abogadosymas/forms/asistenciapropietario"
        );
      }
    } else {
      if (user === "administrador") {
        setSrcForm(forms[0] + action + point + embed);
      } else if (user === "representante") {
        setSrcForm(forms[1] + action + point + embed);
      } else if (user === "propietario") {
        setSrcForm(forms[2] + action + point + embed);
      }
    }
  }

  return (
    <div>
      <Navbar />
      <div className="juntas-container">
        {user === "administrador" && (
          <ControlPanelAdministrador handleClick={handleClick} />
        )}
        {user === "propietario" && (
          <ControlPanelPropietario handleClick={handleClick} />
        )}
        {user === "representante" && (
          <ControlPanelRepresentante handleClick={handleClick} />
        )}
        <div className="juntas-vista-panel">
          <div id="videoconferencia">
            <Videoconference />
          </div>
          <iframe
            src={srcForm}
            title="formulario de accion"
            className="junta-form-action"
          />
          {user === "administrador" && (
            <iframe
              src="https://portal.r2docuo.com/abogadosymas/forms/juntaacceso?embed=1"
              title="formulario de control"
              className="junta-form-action"
            />
          )}
        </div>
      </div>
    </div>
  );
}

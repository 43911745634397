import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./prop.css";

function PropPanel() {
  const [srcForm, setSrcForm] = useState("");
  const [dropdownConsulta, setDropdownConsulta] = useState(false);
  const [dropdownDelegacion, setDropdownDelegacion] = useState(false);
  const [dropdownDatos, setDropdownDatos] = useState(false);
  const history = useHistory();

  const handleClick = (action) => {
    clearDropdowns();
    const urlBase = "https://portal.r2docuo.com/abogadosymas/forms/";
    const embed = "?embed=1";

    setSrcForm(urlBase + action + embed);
  };

  function clearDropdowns() {
    setDropdownConsulta(false);
    setDropdownDelegacion(false);
    setDropdownDatos(false);
  }

  const onMouseClick = (id) => {
    clearDropdowns();
    if (id === "consulta") {
      setDropdownConsulta(!dropdownConsulta);
    } else if (id === "delegacion") {
      setDropdownDelegacion(!dropdownDelegacion);
    } else {
    }
  };

  const onMouseEnter = (id) => {
    if (id === "actualizar") {
      setDropdownDatos(!dropdownDatos);
    }
  };

  const onMouseLeave = () => {
    setDropdownDatos(false);
  };

  return (
    <>
      <div id="Propietario">
        <div id="botonesPropietario">
          <ul>
            <li onClick={() => handleClick("solicitudpropietario")}>
              <i className="fas fa-laptop-house iconButton"></i>
              Sugiere un asunto
            </li>
            <li onClick={() => onMouseClick("consulta")}>
              <i className="fas fa-address-card iconButton"></i>
              Mis datos
              <span className="propietario-espacio-vacio">&nbsp;</span>
              <i className="fas fa-caret-right propietario-flecha-desplegable dashboard-prop-alignRight"></i>
              {dropdownConsulta ? (
                <ul className="dropdownList">
                  <li onClick={() => handleClick("consultapropietarios")}>
                    Consultar mis datos
                  </li>
                  <li
                    onMouseEnter={() => onMouseEnter("actualizar")}
                    onMouseLeave={() => onMouseLeave()}
                  >
                    Actualizar datos
                    <i className="fas fa-caret-right propietario-flecha-desplegable"></i>
                    {dropdownDatos ? (
                      <ul className="dropdownList">
                        <li onClick={() => handleClick("actualizardatos")}>
                          Actualizar mis datos
                        </li>
                        <li onClick={() => handleClick("cambiopropietario")}>
                          Actualizar mis propiedades
                        </li>
                      </ul>
                    ) : (
                      ""
                    )}
                  </li>
                </ul>
              ) : (
                ""
              )}
            </li>
            <li onClick={() => onMouseClick("delegacion")}>
              <i className="fas fa-marker iconButton"></i>
              Convocatorias
              <i className="fas fa-caret-right propietario-flecha-desplegable dashboard-prop-alignRight"></i>
              {dropdownDelegacion ? (
                <ul className="dropdownList">
                  <li onClick={() => handleClick("proximaconvocatoria")}>
                    Próxima convocatoria
                  </li>
                  <li onClick={() => handleClick("solicituddelegacion")}>
                    Delegar mi voto
                  </li>
                  <li onClick={() => handleClick("solicitudrevocacion")}>
                    Revocar mi delegación
                  </li>
                </ul>
              ) : (
                ""
              )}
            </li>
            <li onClick={() => history.push("/sala-de-juntas/propietario")}>
              <i className="fas fa-chalkboard-teacher iconButton"></i>
              Ir a la junta
            </li>
          </ul>
        </div>
        <div id="contFormPropietario">
          <iframe
            id="formPropietario"
            title="Vista de contenido del panel de control"
            src={srcForm}
          ></iframe>
        </div>
      </div>
    </>
  );
}

export default PropPanel;

import React from "react";
import { useHistory } from "react-router-dom";
import { Session } from "bc-react-session";
import Navbar from "./components/navbar/navbar";
import "./home.css";

export default function Home() {
  const history = useHistory();
  const { payload } = Session.get();
  const isLogged = Object.entries(payload).length !== 0;
  const username = isLogged ? payload.name : "";

  return (
    <div>
      <div className="home-container">
        <Navbar />
        <div className="home-page-container">
          <h1 id="welcomeMessage">ConMiAPP by Convoco </h1>
          {!isLogged ? (
            <div>
              <p className="home-option-text">¿Asistes a una junta?¿Eres Propietario?</p>
              <div className="home-container-buttons">
              <button
                className="home-login-button home-button-junta"
                onClick={() => history.push("/sala-de-juntas/propietario")}
              >
                Si
              </button>
              <button
                className="home-login-button home-button-junta"
                onClick={() => history.push("/sala-de-juntas/representante")}
              >
                No
              </button>
              </div>
            </div>
          ) : (
            ""
          )}
          <div>
            {!isLogged ? (
              <>
                <p className="home-option-text">¿Estás registrado?</p>
                <button
                  className="home-login-button"
                  onClick={() => history.push("/login")}
                >
                  ¡Entra!
                </button>
              </>
            ) : (
              <>
                <p className="home-option-text">
                  ¡Bienvenido de nuevo, {username}
                </p>
                <button
                  className="home-login-button home-user-is-back"
                  onClick={() => history.push("/dashboard")}
                >
                  Entrar al panel de control
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

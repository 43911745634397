import React, { useState } from "react";
import "./cAdmin.css";

function ControlPanelAdministrador({ handleClick }) {
  const [displayRootVote, setDisplayRootVote] = useState(false);
  const [displayVote1, setDisplayVote1] = useState(false);
  const [displayVote2, setDisplayVote2] = useState(false);
  const [displayVote3, setDisplayVote3] = useState(false);
  const [displayVote4, setDisplayVote4] = useState(false);
  const [displayVote5, setDisplayVote5] = useState(false);
  const [displayVote6, setDisplayVote6] = useState(false);
  const [displayVote7, setDisplayVote7] = useState(false);
  const [displayVote8, setDisplayVote8] = useState(false);
  const [displayVote9, setDisplayVote9] = useState(false);
  const [displayVote10, setDisplayVote10] = useState(false);
  const [displayVote11, setDisplayVote11] = useState(false);
  const [displayVote12, setDisplayVote12] = useState(false);

  const handleMouseEnter = () => {
    setDisplayRootVote(true);
  };

  const handleMouseLeave = () => {
    setDisplayRootVote(false);
  };

  const onMouseEnter = (vote) => {
    switch (vote) {
      case 1:
        setDisplayVote1(true);
        break;
      case 2:
        setDisplayVote2(true);
        break;
      case 3:
        setDisplayVote3(true);
        break;
      case 4:
        setDisplayVote4(true);
        break;
      case 5:
        setDisplayVote5(true);
        break;
      case 6:
        setDisplayVote6(true);
        break;
      case 7:
        setDisplayVote7(true);
        break;
      case 8:
        setDisplayVote8(true);
        break;
      case 9:
        setDisplayVote9(true);
        break;
      case 10:
        setDisplayVote10(true);
        break;
      case 11:
        setDisplayVote11(true);
        break;
      case 12:
        setDisplayVote12(true);
        break;
      default:
        break;
    }
  };

  const onMouseLeave = () => {
    setDisplayVote1(false);
    setDisplayVote2(false);
    setDisplayVote3(false);
    setDisplayVote4(false);
    setDisplayVote5(false);
    setDisplayVote6(false);
    setDisplayVote7(false);
    setDisplayVote8(false);
    setDisplayVote9(false);
    setDisplayVote10(false);
    setDisplayVote11(false);
    setDisplayVote12(false);
  };

  return (
    <div className="juntas-control-panel jpc-admin">
      <ul>
        <li onClick={() => handleClick("registro", 1)}>
          Registrar asistentes
        </li>
        <li
          onMouseEnter={() => handleMouseEnter()}
          onMouseLeave={() => handleMouseLeave()}
        >
          Registrar voto
          {displayRootVote && (
            <ul className="junta-administrador-dropdown-list">
              <li
                onMouseEnter={() => onMouseEnter(1)}
                onMouseLeave={() => onMouseLeave()}
                className="junta-administrador-dropdown-list-item"
              >
                Votar punto 1
                {displayVote1 && (
                  <ul className="junta-administrador-dropdown-list">
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("favorpunto", 1)}
                    >
                      A favor
                    </li>
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("contrapunto", 1)}
                    >
                      En contra
                    </li>
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("abstencionpunto", 1)}
                    >
                      Abstención
                    </li>
                  </ul>
                )}
              </li>
              <li
                onMouseEnter={() => onMouseEnter(2)}
                onMouseLeave={() => onMouseLeave()}
                className="junta-administrador-dropdown-list-item"
              >
                Votar punto 2
                {displayVote2 && (
                  <ul className="junta-administrador-dropdown-list">
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("favorpunto", 2)}
                    >
                      A favor
                    </li>
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("contrapunto", 2)}
                    >
                      En contra
                    </li>
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("abstencionpunto", 2)}
                    >
                      Abstención
                    </li>
                  </ul>
                )}
              </li>
              <li
                onMouseEnter={() => onMouseEnter(3)}
                onMouseLeave={() => onMouseLeave()}
                className="junta-administrador-dropdown-list-item"
              >
                Votar punto 3
                {displayVote3 && (
                  <ul className="junta-administrador-dropdown-list">
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("favorpunto", 3)}
                    >
                      A favor
                    </li>
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("contrapunto", 3)}
                    >
                      En contra
                    </li>
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("abstencionpunto", 3)}
                    >
                      Abstención
                    </li>
                  </ul>
                )}
              </li>
              <li
                onMouseEnter={() => onMouseEnter(4)}
                onMouseLeave={() => onMouseLeave()}
                className="junta-administrador-dropdown-list-item"
              >
                Votar punto 4
                {displayVote4 && (
                  <ul className="junta-administrador-dropdown-list">
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("favorpunto", 4)}
                    >
                      A favor
                    </li>
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("contrapunto", 4)}
                    >
                      En contra
                    </li>
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("abstencionpunto", 4)}
                    >
                      Abstención
                    </li>
                  </ul>
                )}
              </li>
              <li
                onMouseEnter={() => onMouseEnter(5)}
                onMouseLeave={() => onMouseLeave()}
                className="junta-administrador-dropdown-list-item"
              >
                Votar punto 5
                {displayVote5 && (
                  <ul className="junta-administrador-dropdown-list">
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("favorpunto", 5)}
                    >
                      A favor
                    </li>
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("contrapunto", 5)}
                    >
                      En contra
                    </li>
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("abstencionpunto", 5)}
                    >
                      Abstención
                    </li>
                  </ul>
                )}
              </li>
              <li
                onMouseEnter={() => onMouseEnter(6)}
                onMouseLeave={() => onMouseLeave()}
                className="junta-administrador-dropdown-list-item"
              >
                Votar punto 6
                {displayVote6 && (
                  <ul className="junta-administrador-dropdown-list">
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("favorpunto", 6)}
                    >
                      A favor
                    </li>
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("contrapunto", 6)}
                    >
                      En contra
                    </li>
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("abstencionpunto", 6)}
                    >
                      Abstención
                    </li>
                  </ul>
                )}
              </li>
              <li
                onMouseEnter={() => onMouseEnter(7)}
                onMouseLeave={() => onMouseLeave()}
                className="junta-administrador-dropdown-list-item"
              >
                Votar punto 7
                {displayVote7 && (
                  <ul className="junta-administrador-dropdown-list">
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("favorpunto", 7)}
                    >
                      A favor
                    </li>
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("contrapunto", 7)}
                    >
                      En contra
                    </li>
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("abstencionpunto", 7)}
                    >
                      Abstención
                    </li>
                  </ul>
                )}
              </li>
              <li
                onMouseEnter={() => onMouseEnter(8)}
                onMouseLeave={() => onMouseLeave()}
                className="junta-administrador-dropdown-list-item"
              >
                Votar punto 8
                {displayVote8 && (
                  <ul className="junta-administrador-dropdown-list">
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("favorpunto", 8)}
                    >
                      A favor
                    </li>
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("contrapunto", 8)}
                    >
                      En contra
                    </li>
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("abstencionpunto", 8)}
                    >
                      Abstención
                    </li>
                  </ul>
                )}
              </li>
              <li
                onMouseEnter={() => onMouseEnter(9)}
                onMouseLeave={() => onMouseLeave()}
                className="junta-administrador-dropdown-list-item"
              >
                Votar punto 9
                {displayVote9 && (
                  <ul className="junta-administrador-dropdown-list">
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("favorpunto", 9)}
                    >
                      A favor
                    </li>
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("contrapunto", 9)}
                    >
                      En contra
                    </li>
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("abstencionpunto", 9)}
                    >
                      Abstención
                    </li>
                  </ul>
                )}
              </li>
              <li
                onMouseEnter={() => onMouseEnter(10)}
                onMouseLeave={() => onMouseLeave()}
                className="junta-administrador-dropdown-list-item"
              >
                Votar punto 10
                {displayVote10 && (
                  <ul className="junta-administrador-dropdown-list">
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("favorpunto", 10)}
                    >
                      A favor
                    </li>
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("contrapunto", 10)}
                    >
                      En contra
                    </li>
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("abstencionpunto", 10)}
                    >
                      Abstención
                    </li>
                  </ul>
                )}
              </li>
              <li
                onMouseEnter={() => onMouseEnter(11)}
                onMouseLeave={() => onMouseLeave()}
                className="junta-administrador-dropdown-list-item"
              >
                Votar punto 11
                {displayVote11 && (
                  <ul className="junta-administrador-dropdown-list">
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("favorpunto", 11)}
                    >
                      A favor
                    </li>
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("contrapunto", 11)}
                    >
                      En contra
                    </li>
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("abstencionpunto", 11)}
                    >
                      Abstención
                    </li>
                  </ul>
                )}
              </li>
              <li
                onMouseEnter={() => onMouseEnter(12)}
                onMouseLeave={() => onMouseLeave()}
                className="junta-administrador-dropdown-list-item"
              >
                Votar punto 12
                {displayVote12 && (
                  <ul className="junta-administrador-dropdown-list">
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("favorpunto", 12)}
                    >
                      A favor
                    </li>
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("contrapunto", 12)}
                    >
                      En contra
                    </li>
                    <li
                      className="junta-administrador-dropdown-list-item"
                      onClick={() => handleClick("abstencionpunto", 12)}
                    >
                      Abstención
                    </li>
                  </ul>
                )}
              </li>
            </ul>
          )}
        </li>
      </ul>
    </div>
  );
}

export default ControlPanelAdministrador;
